import api from '@/js/services/ajax'
import urlUtil from '@/js/utils/urlUtil'

export default {

  find (hideResolved, hideApproved) {
    const queryMap = new Map()
    queryMap.set('hide_resolved', hideResolved)
    queryMap.set('hide_approved', hideApproved)

    var query = urlUtil.prepareQuery(queryMap)
    return new Promise((resolve, reject) => {
      api
        .get(`/api/admin_v1/image-feedback${query}`)
        .then((res) => {
          let feedbackList = res.data
          resolve(feedbackList)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
