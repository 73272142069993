import { createStore } from 'vuex'
import userModule from './modules/user'
import mediaEditorModule from './modules/mediaEditor'
import feedbackModule from './modules/feedback'
import memberModule from './modules/members'
import postModule from './modules/post'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  modules: {
    user: userModule,
    mediaEditor: mediaEditorModule,
    feedback: feedbackModule,
    member: memberModule,
    post: postModule
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ['user'],
      key: 'azepls'
    }),
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['mediaEditor', 'feedback', 'member', 'post'],
      key: 'azepss'
    })
  ]
})