import UserRepository from '@/js/api/UserRepository'

const getDefaultState = () => {
  return {
    memberList: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setMemberList (state, memberList){
      Object.assign(state, getDefaultState())
      state.memberList = memberList
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    reloadMemberList ({ commit }) {
      UserRepository.getUserList()
      .then(users => {
        users = users.filter(user => user.role === 'member' || user.role === 'superadmin')
        commit('setMemberList', users)
      })
      .catch(error => {
        throw error
      })
    }
  },
  getters: {
    getMemberById: (state) => (id) => {
      if (!id) {
        return null
      }

      id = parseInt(id)
      const itemsById = state.memberList.filter(item => item.id === id)
      if (itemsById) {
        return itemsById[0]
      } else {
        return null
      }
    },
  }
}
