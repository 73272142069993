import store from '@/store/store'
import router from '@/router'
import api from '@/js/services/ajax'

const getDefaultState = () => {
  return {
    editorToken: '',
    user: ''
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setUser (state, payload) {
      state.editorToken = payload.token
      state.user = {}
      state.user.id = payload.id
      state.user.role = payload.role
    },
    setUserDetails (state, user) {
      state.user.email = user.email
      state.user.fullName = user.full_name
      state.user.initial = user.initial
    },
    logout (state) {
      state.editorToken = ''
      state.user = {}
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    changeUserDetails ({ commit }, user) {
      commit('setUserDetails', user)
    },

    authorize ({ commit }, userPayload) {
      commit('setUser', userPayload)
      commit('setUserDetails', userPayload)
    },

    logout () {
      api
        .post('logout')
        .then(() => {
          store.dispatch('user/removeLoggedInUserData')
        })
        .catch((error) => {
          if (error.response.status === 401) {
            store.dispatch('user/removeLoggedInUserData')
            return
          }
          throw error
        })
    },

    removeLoggedInUserData ({ commit }) {
      commit('logout')
      router.go()
    }
  },
  getters: {
    editorToken: state => state.editorToken,
    user: state => state.user,
    userId: state => state.user.id,
    countryId: state => state.user.countryId,
    isLoggedIn: state => !!state.editorToken,
    fullName: state => state.user.fullName,
    initial: state => state.user.initial
  }
}
