import {useToast} from 'vue-toast-notification';
import axios from 'axios'
import store from '@/store/store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

api.interceptors.request.use(
  config => {
    const token = store.getters['user/editorToken']
    if (token) {
      config.headers.Authorization = token
    }


    // To be able to use different version for some resources
    var url = config.url.toLowerCase()
    if (url.startsWith('/')) {
      config.baseURL = config.baseURL.slice(0, -7)
    }

    if (url.startsWith('v2/')) {
      var baseUrl = config.baseURL
      baseUrl = baseUrl.slice(0, -3)
      config.baseURL = baseUrl
    }
    return config
  },

  error => Promise.reject(error)
)

api.interceptors.response.use(
  (response) => {
    return response
  },

  (error) => {
    const resp = error.response
    if (resp){
      if (resp.status === 400) {
        if (resp.data.message){
          console.log('Received 400 error!', resp, resp.data.message)
          useToast().open({ 
            position: "top",
            type: 'error',
            message: resp.data.message
          })
        }
      } else if (resp.status === 401) {
        if (store.getters['user/isLoggedIn'] === true) {
          store.dispatch('user/logout')
        }
      } else if (resp.status === 500) {
        if (resp.data.message){
          console.log('Received 500 error!', resp, resp.data.message)
          useToast().open({ 
            position: "top",
            type: 'error',
            message: 'Server error!'
          })
        }
      }

    }

    return Promise.reject(error)
  })

export default api
