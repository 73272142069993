import api from '@/js/services/ajax'
import store from '@/store/store'

export default {

  getUserList () {
    return new Promise((resolve, reject) => {
      api
        .get('/api/admin_v1/users')
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getMyUser () {
    return new Promise((resolve, reject) => {
      const userId = store.getters['user/userId']
      api
        .get('v2/users/' + userId)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  login (username, password) {
    return new Promise((resolve, reject) => {
      const payload = {}
      payload.email = username
      payload.password = password
      api
        .post('login', payload)
        .then((response) => {
          const user = response.data
          if (user.role !== 'superadmin')
            throw Error('Role "' + user.role + '" not allowed')

          store.dispatch('user/authorize', user)
          resolve(user)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
