import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from '@/store/store'

import '@/assets/css/custom.css'

import VueEasyLightbox from 'vue-easy-lightbox'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueToast from 'vue-toast-notification';
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(VueLoading)
  .use(VueEasyLightbox)
  .use(VueSweetalert2, {
    showCancelButton: true,
    confirmButtonColor: '#3085d6'
  })
  .use(VueToast, {
    // One of the options
    position: 'top'
})
  .mount('#app')
