import api from '@/js/services/ajax'
import urlUtil from '@/js/utils/urlUtil'
import objectUtil from '@/js/utils/objectUtil'
import stringUtil from '@/js/utils/stringUtil'

const MEDIA_BASE_URL = process.env.VUE_APP_MEDIA_BASE_URL

export default {

  getBy (status, userId, excludeFeedback, feedback, pageSize, page, orderBy, orderByDirection) {
    const queryMap = new Map()
    queryMap.set('status', status)
    queryMap.set('user_id', userId)
    queryMap.set('exclude_Feedback', excludeFeedback)
    queryMap.set('feedback', feedback)
    queryMap.set('orderBy', orderBy)
    queryMap.set('orderByDirection', orderByDirection)
    queryMap.set('pageSize', pageSize)
    queryMap.set('page', page)

    var query = urlUtil.prepareQuery(queryMap)
    return new Promise((resolve, reject) => {
      api
        .get(`/api/admin_v1/images${query}`)
        .then((res) => {
          let images = res.data

          images = this._prepareImages(images)
          resolve(images)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  getStatisticsByUser (status) {
    const queryMap = new Map()
    queryMap.set('status', status)

    var query = urlUtil.prepareQuery(queryMap)
    return new Promise((resolve, reject) => {
      api
        .get(`/api/admin_v1/images/statisticsByUser${query}`)
        .then((res) => {
          let statistics = res.data
          resolve(statistics)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  approveMedia(mediaIds) {
    return new Promise((resolve, reject) => {
      api
        .post('/api/admin_v1/images/approve', { ids: mediaIds })
        .then((res) => {
          let images = res.data
          images = this._prepareImages(images)
          resolve(images)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  rejectMedia(mediaIds, note) {
    return new Promise((resolve, reject) => {
      api
        .post('/api/admin_v1/images/reject', { ids: mediaIds, note: note })
        .then((res) => {
          let images = res.data
          images = this._prepareImages(images)
          resolve(images)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  sendFeedback(mediaIds, message) {
    return new Promise((resolve, reject) => {
      api
        .post('/api/admin_v1/feedback', { ids: mediaIds, message: message })
        .then((res) => {
          let result = res.data
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  
  updateMedia (media) {
    if (!media || !media.id) {
      throw new Error('Problems with media parameter: ' + media, media)
    }

    const json = {}
    json.title_sv = media.title_sv
    json.title_en = media.title_en
    json.caption_sv = media.caption_sv
    json.caption_en = media.caption_en
    json.restrictions_sv = media.restrictions_sv
    json.restrictions_en = media.restrictions_en
    json.locations_sv = media.locations_sv
    json.locations_en = media.locations_en
    json.image_type = media.image_type
    json.orientation = media.orientation
    json.model = media.model
    json.author = media.author

    return new Promise((resolve, reject) => {
      api.patch('/api/admin_v1/images/' + media.id, json)
        .then((res) => {
          let media = res.data
          media = this._prepareImage(media)
          resolve(media)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  _prepareImages (imageArray) {
    var resultArray = []
    if (!imageArray) {
      return resultArray
    }

    imageArray.forEach((image) => {
      resultArray = resultArray.concat(this._prepareImage(image))
    })
    return resultArray
  },
  _prepareImage (image) {
    if (!image) {
      return null
    }

    if (!image.title) {
      image.title = ''
    }

    image.date_taken = objectUtil.getDateWithoutTimezone(image.date_taken)
    image.image_ref = image.filename.substring(0, image.filename.lastIndexOf('.'))

    let imageUrl = ''
    if (image.image_type === 'video') {
      imageUrl += '/video/'
    } else {
      imageUrl += '/image/'
    }
    imageUrl += encodeURIComponent(stringUtil.replaceSpaces(image.title.trim(), '-')).toLowerCase() + '/' + image.id
    image.url = imageUrl

    let byline = 'azotelibrary.com'
    const owner = image.owner
    let author = image.author
    if (owner) {
      byline = owner + '/' + byline
    }
    if (author && (!owner || author.toUpperCase() !== owner.toUpperCase())) {
      byline = author + '/' + byline
    }
    image.byline = byline

    if (!author) {
      author = image.owner
    }
    image.author = author

    const height = image.height
    const width = image.width

    const resolutionMax = height * width
    let resolution = null
    if (resolutionMax >= 3840 * 2160) {
      resolution = '4K'
    } else if (resolutionMax >= 1920 * 1080) {
      resolution = 'HD'
    }
    image.resolution = resolution

    image.keywordsList = this._textToArray(image.keywords)
    image.keywordsTaxonomicList = this._textToArray(image.keywords_taxonomic)
    image.keywordsSvList = this._textToArray(image.keywords_sv)
    image.keywordsEnList = this._textToArray(image.keywords_en)
    image.locationsList = this._textToArray(image.locations)

    this._fillImageSrcDetails(image)

    image.isProcessed = false
    return image
  },
  _textToArray (text1) {
    var tmpArray = []
    var tmpText = text1
    if (tmpText) {
      tmpArray = tmpText.trim()
        .split(',')
        .map(item => item.trim())
        .filter(String)
    }
    return tmpArray
  },

  _fillImageSrcDetails(image){
    if (!image.image_ref && image.filename){
      if (image.filename.includes('.')){
        image.image_ref = image.filename.substring(0, image.filename.lastIndexOf('.'))
      } else {
        image.image_ref = image.filename
      }
    }
    
    let srcImageBase = MEDIA_BASE_URL
    let srcVideoBase = MEDIA_BASE_URL
    if (image.image_type === 'video') {
      srcImageBase += '/video/cover/'
      srcVideoBase += '/video/preview/'

      image.src_video_400 = srcVideoBase + 't400/' + image.image_ref + '.mp4'
      image.src_video_600 = srcVideoBase + 'w600/' + image.image_ref + '.mp4'
      image.src_video_900 = srcVideoBase + 'w900/' + image.image_ref + '.mp4'
      image.src_video_1300 = srcVideoBase + 'w1300/' + image.image_ref + '.mp4'
    } else {
      srcImageBase += '/image/'
    }

    image.src_image_400 = srcImageBase + 't400/' + image.image_ref + '.webp'
    image.src_image_600 = srcImageBase + 'w600/' + image.image_ref + '.webp'
    image.src_image_900 = srcImageBase + 'w900/' + image.image_ref + '.webp'
    image.src_image_1300 = srcImageBase + 'w1300/' + image.image_ref + '.webp'
  }
}
