import stringUtil from '@/js/utils/stringUtil'

export default {
  clone (object) {
    if (!object) {
      return null
    }

    return JSON.parse(JSON.stringify(object))
  },

  getDateWithoutTimezone (date) {
    if (stringUtil.isEmpty(date)) {
      return null
    }

    try {
      date = new Date(date)
      return new Date(date - date.getTimezoneOffset() * 60000).toISOString().substr(0, 10)
    } catch (e) {
      console.error('Failed to convert date => ', date)
      return null
    }
  },

  getDimension (maxDimension, mainDimension, secondDimension) {
    if (mainDimension > secondDimension) {
      return maxDimension
    }
    try {
      const weight = secondDimension / maxDimension
      const resultDimension = parseInt(mainDimension / weight)
      return resultDimension
    } catch (e) {
      console.error('Failed to calculate image dimension by values', maxDimension, mainDimension, secondDimension)
      return null
    }
  }
}
