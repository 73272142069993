import objectUtil from "@/js/utils/objectUtil"

const getDefaultState = () => {
  return {
    postList: [],
    imageRefsForPost: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setPostList (state, postList){
      state.postList = postList
    },
    updatePost(state, post){
      if (!post) {
        return
      }

      let postIdList = state.postList.map(postItem => postItem.id)
      if (postIdList.includes(post.id)){
        post = objectUtil.clone(post)
        const item = state.postList.find(item => item.id === post.id)
        Object.assign(item, post)
      } else {
        state.postList.unshift(post)
      }
    },
    storeImageRefForPost(state, imageRef){
      if (!state.imageRefsForPost.includes(imageRef)){
        state.imageRefsForPost.push(imageRef)
      }
    },
    removeImageRefForPost(state, imageRef){
      state.imageRefsForPost = state.imageRefsForPost.filter(imageItem => imageItem !== imageRef)
    },
    clearImageRefs(state){
      state.imageRefsForPost = []
    },
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    setPostList ({ commit }, postList) {
      commit('setPostList', postList)
    },
    updatePost({ commit }, post) {
      commit('updatePost', post)
    },
    storeImageRefForPost({ commit }, imageRef) {
      commit('storeImageRefForPost', imageRef)
    },
    removeImageRefForPost({ commit }, imageRef) {
      commit('removeImageRefForPost', imageRef)
    },
    clearImageRefs({ commit }) {
      commit('clearImageRefs')
    },
  },

  getters: {
    postList: state => state.postList,
    imageRefsForPost: state => state.imageRefsForPost
  }
}
