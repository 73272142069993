export default {
  webURL: process.env.VUE_APP_WEB_URL,

  prepareQuery (queryMap) {
    var query = ''
    for (var [key, value] of queryMap.entries()) {
      if (!query) {
        query += '?'
      } else {
        query += '&'
      }

      if (value === 0) {
        value = '0'
      } else if (!value) {
        value = ''
      }

      query += key + '=' + this.encodeParam(value)
    }
    return query
  },

  encodeParam (value) {
    if (!value) {
      return ''
    }
    return encodeURIComponent(value)
  },


  getWebUrl(path){
    if (!path){
      path = ''
    }

    if (path.startsWith('/')){
      path = path.substring(1)
    }
    return this.webURL + '/' + path
  },
}
