import ImageFeedbackRepository from '@/js/api/ImageFeedbackRepository'

const getDefaultState = () => {
  return {
    feedbackList: [],
    imagesWithFeedbackList: [],
    imageFeedbackToApproveList: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setFeedbackList (state, feedbackList){
      state.feedbackList = feedbackList
    },
    setImagesWithFeedbackList (state, payload){
      state.imagesWithFeedbackList = payload.imagesWithFeedbackList
      state.imageFeedbackToApproveList = payload.imageFeedbackToApproveList
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    setFeedbackList ({ commit }, feedbackList) {
      commit('setFeedbackList', feedbackList)
    },

    reloadImageFeedbackList ({ commit }) {
      ImageFeedbackRepository.find(false, true)
      .then(imageFeedbackList => {
        let imagesWithFeedbackList = imageFeedbackList.map(obj => obj.image_id)
        let imageFeedbackToApproveList = imageFeedbackList.filter(imageFeedback => imageFeedback.resolved && !imageFeedback.approved_time).map(obj => obj.image_id)
        commit('setImagesWithFeedbackList', {imagesWithFeedbackList: imagesWithFeedbackList, imageFeedbackToApproveList: imageFeedbackToApproveList})
      })
    },

    addImagesToFeedbackList ({ commit, getters }, imageIds) {
      let imagesWithFeedbackList = getters.imagesWithFeedbackList.concat(imageIds)
      let imageFeedbackToApproveList = getters.imageFeedbackToApproveList
      commit('setImagesWithFeedbackList', { imagesWithFeedbackList: imagesWithFeedbackList, imageFeedbackToApproveList: imageFeedbackToApproveList})
    },
  },

  getters: {
    feedbackList: state => state.feedbackList,
    imagesWithFeedbackList: state => state.imagesWithFeedbackList,
    imageFeedbackToApproveList: state => state.imageFeedbackToApproveList,
  }
}
