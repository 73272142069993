<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="orange"
          variant="outlined"
          v-bind="props"
          :disabled="!mediaIds || mediaIds.length <= 0" 
        >
          Feedback
        </v-btn>
      </template>
      <v-card v-if="dialog && mediaList" fullscreen min-width="1000px">
        <v-card-title>
          <span class="text-h5">Feedback to media: <i>{{ imageRefs }}</i></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="my-0">
                <v-col cols="12">
                    <v-textarea 
                        v-model="message" 
                        rows="12"
                        hide-details="auto">
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row class="my-0">
                <v-col cols="12">
                  Phrases:
                  <v-chip 
                    v-for="(text, index) in textList"
                    v-bind:key="index"
                    @click="addTextToMessage(text.description)"
                    class="ma-1">
                    {{ text.title }}
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  Feedback:
                  <v-chip 
                    v-for="(feedback, index) in feedbackList"
                    v-bind:key="index"
                    @click="addFeedbackPointToMessage(feedback.description)"
                    class="ma-1">
                    {{ feedback.title }}
                  </v-chip>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue-darken-1"
            text
            @click="sendFeedback"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MediaRepository from '@/js/api/MediaRepository'

export default {
    props: ['mediaIds'],
    data () {
        return {
            dialog: false,
            mediaList: null,
            message: null,
            feedbackUserId: null,
            feedbackOption: null,
            feedbackList: [
              { title: 'Ofullständig text', description: 'Fyll i alla textfält.'},
              { title: 'Fotografens namn', description: 'Ta bort den andra referensen till ditt namn (den finns redan ovan).'},
              { title: 'Titlar och bildtexter', description: 'Titlar och bildtexter börjar med stor bokstav. Bildtexter är (när möjligt) fullständiga meningar som slutar med punkt.'},
              { title: 'Fotografhandboken', description: 'Bild och text- hantering behandlas i fotografhandboken.  Läs den gärna och ofta! :-)'},
              { title: 'Dubletter', description: 'Det räcker med ett par liknande bilder. Gallra. Säljer dessa så kan du ladda upp fler.'},
            ],
            textList: [
              { title: 'Hej!', description: 'Hej! Tack för dina bilder. Vänligen fixa nedan så att vi kan publicera:'},
              { title: 'Frågor?', description: 'Maila oss på support@azotelibrary.com om du har frågor.'},
              { title: 'Signature', description: '\nsupport@azotelibrary.com'},
              { title: 'Utvecklingsfas', description: 'Vi är i utvecklingsfasen av feedback funktionen. Hör gärna av dig om något är oklart.'},
            ]
        }
    },
    computed: {
      imageRefs: function() {
        return this.mediaList.map(media => media.image_ref).join(", ")
      }
    },
    watch: {
      dialog: function() {
          if (this.dialog && !this.mediaList){
              let mediaList = this.$store.getters['mediaEditor/mediaList']
              mediaList = mediaList.filter(media => this.mediaIds.includes(media.id))
              if (mediaList.length < 1){
                this.$toast.error('No media selected for feedback!')
                this.dialog = false
                return
              }

              let ownerCount = new Set(mediaList.map(media => media.owner_id)).size
              if (ownerCount > 1){
                this.$toast.error('Feedback can be sent to one member. Selected media for ' + ownerCount + ' different members')
                this.dialog = false
                return
              }

              this.mediaList = mediaList
              let media = mediaList[0]
              let feedbackUserId = media.owner_id
              if (!this.message || this.feedbackUserId !== feedbackUserId){
                this.feedbackUserId = feedbackUserId
                let user = this.$store.getters['member/getMemberById'](this.feedbackUserId)
                let firstName = (user ? user.first_name : '') || '' 
                this.message = 'Hej ' + firstName + '!\nTack för dina bilder. Vänligen fixa nedan så att vi kan publicera:'
              }
          }
      },
      mediaIds: function() {
        if (this.mediaIds){
          this.mediaList = null
        }
      },
      feedbackOption: function(val){
        this.message = val.description
      }
    },
    methods: {
      addFeedbackPointToMessage: function(value) {
        if (!this.message){
          this.message = ""
        }
        this.message += "\n ○ " + value
      },
      addTextToMessage: function(value) {
        if (!this.message){
          this.message = ""
        }
        this.message += "\n" + value
      },
      sendFeedback: function(){
          const loader = this.$loading.show()
            MediaRepository.sendFeedback(this.mediaIds, this.message)
            .then(() => {
                this.$store.dispatch('mediaEditor/markReview', this.mediaIds)
                this.dialog = false
                this.message = null
                this.mediaList = null
            })
            .catch(error => {
                throw error
            })
            .finally(() => {
                loader.hide()
            })

      }
    }
}
</script>