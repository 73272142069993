import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store'
import HomeLayout from '../views/private/layout/HomeLayout.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "home" */ '../views/LoginPage.vue')
  },
  {
    path: '/private',
    component: HomeLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "account" */ '../views/private/HomeView.vue')
      },
      {
        path: 'media',
        component: () => import(/* webpackChunkName: "mediaGrid" */ '../views/private/MediaGridView.vue'),
      },
      {
        path: 'feedback',
        component: () => import(/* webpackChunkName: "feedbackGrid" */ '../views/private/FeedbackGridView.vue'),
      },
      {
        path: 'post',
        component: () => import(/* webpackChunkName: "postGrid" */ '../views/private/PostGridView.vue'),
      },
      {
        path: 'site-settings',
        component: () => import(/* webpackChunkName: "siteSettings" */ '../views/private/SiteSettingsView.vue'),
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  let isLoggedIn = store.getters['user/isLoggedIn']
  if (isLoggedIn && !to.fullPath.startsWith('/private')){
    next({ path: '/private' })
    return
  }
  if (!isLoggedIn && to.matched.some(record => record.meta.requiresAuth)) {
    next({ path: '/', query: { returnUrl: to.path } })
    return
  }
  
  next()
})

export default router
