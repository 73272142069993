import mediaRepository from '@/js/api/MediaRepository'

const getDefaultState = () => {
  return {
    mediaList: [],
    selectedMediaIdList: [],
    isListProcessed: false,
    totalMediaCount: null,
    withFeedbackCount: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setMediaList (state, mediaList){
      Object.assign(state, getDefaultState())
      state.mediaList = mediaList
      state.selectedMediaIdList = mediaList.map(media => media.id)
    },
    setSelectedMediaIdList (state, selectedMediaIdList) {
      state.selectedMediaIdList = selectedMediaIdList
    },
    setStatistics (state, statistics) {
      state.totalMediaCount = statistics.totalMediaCount
      state.withFeedbackCount = statistics.withFeedbackCount
    },
    updateMediaList (state, mediaList){
      let tmpMediaList = state.mediaList
      for (let index = 0; index < tmpMediaList.length; ++index){
        let id = tmpMediaList[index].id
        let media = mediaList.find(media => media.id === id)
        if (media){
          tmpMediaList[index] = media
        }
      }
      state.mediaList = tmpMediaList

      let updatedMediaIds = mediaList.filter(media => media.status !== 'pending' || media.isProcessed).map(media => media.id)
      state.selectedMediaIdList = state.selectedMediaIdList.filter(id => !updatedMediaIds.includes(id))

      state.isListProcessed = state.mediaList.filter(media => media.status === 'pending' && !media.isProcessed).length === 0
    }
  },

  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },

    setMediaList ({ commit }, mediaList) {
      commit('setMediaList', mediaList)
    },
    setStatistics ({ commit }, statistics) {
      commit('setStatistics', statistics)
    },

    setSelectedMediaIdList({ commit }, selectedMediaIdList) {
      commit('setSelectedMediaIdList', selectedMediaIdList)
    },
    
    updateMediaList({ commit }, mediaList) {
      commit('updateMediaList', mediaList)
    },

    approveSelectedMediaList({ commit, getters }){
      mediaRepository.approveMedia(getters.selectedMediaIdList)
      .then((images) => {
        commit('updateMediaList', images)
      })
      .catch((error) => {
        throw error
      })
    },

    rejectMedia({ commit }, payload){
      mediaRepository.rejectMedia([payload.id], payload.note)
      .then((images) => {
        commit('updateMediaList', images)
      })
      .catch((error) => {
        throw error
      })
    },

    markReview({ commit, getters }, mediaIds){
      let mediaList = getters.mediaList.filter(media => mediaIds.includes(media.id))
      mediaList.forEach(media => {
        media.isProcessed = true
      });
      commit('updateMediaList', mediaList)
    },
  },
  getters: {
    mediaList: state => state.mediaList,
    selectedMediaIdList: state => state.selectedMediaIdList,
    isListProcessed: state => state.isListProcessed,
    totalMediaCount: state => state.totalMediaCount,
    withFeedbackCount: state => state.withFeedbackCount,
    getById: (state) => (id) => {
      if (!id) {
        return null
      }

      id = parseInt(id)
      const itemsById = state.mediaList.filter(item => item.id === id)
      if (itemsById) {
        return itemsById[0]
      } else {
        return null
      }
    },
  }
}
