<template>
  <v-app :theme="theme">
    <v-navigation-drawer
        :rail="rail"
        permanent
      >
        <v-list-item
          prepend-avatar="/img/mnb_4077.jpg"
          :title="fullName"
        />

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item 
            v-for="item in items" 
            v-bind:key="item.title" 
            :prepend-icon="item.icon" 
            :title="item.title"
            :to="item.link"></v-list-item>
        </v-list>

        <template v-slot:append>
          <div class="pa-2 ml-2">
            <v-switch
              v-model="theme"
              label="Dark mode"
              hide-details
              true-value="dark"
              false-value="light"
            ></v-switch>

            <v-btn
              icon
              @click.stop="rail = !rail"
              class="mb-2"
            >
              <v-icon v-if="!rail">mdi-chevron-left</v-icon>
              <v-icon v-else>mdi-chevron-right</v-icon>
            </v-btn>

            <v-btn block @click="logout()">
              <span v-if="!rail">Logout</span>
              <v-icon v-else>mdi-logout</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

    <v-app-bar app v-if="isEditorPanel">
      Pending: {{ totalMediaCount - withFeedbackCount}} <i v-if="withFeedbackCount > 0" class="color-grey ml-1"> ({{ withFeedbackCount }} waiting feedback)</i>
      <v-spacer></v-spacer>
      <v-btn 
        variant="outlined" 
        color="green" 
        :disabled="selectedMediaCount === 0"
        @click="actionApproveSelectedMedia()">Approve ({{ selectedMediaCount }})</v-btn>

      <MediaFeedbackDialog class="ml-1" :mediaIds="selectedMediaIdList"/>
    </v-app-bar>
    <v-app-bar app v-else>
      
    </v-app-bar>


    <v-main>
      <v-container fluid>
        <router-view :key="$route.fullPath"/>
      </v-container>
    </v-main>

    <v-footer app>
    </v-footer>
  </v-app>
</template>

<script>
import MediaFeedbackDialog from '@/components/media/MediaFeedbackDialog'

export default {
  components: { MediaFeedbackDialog },
  data: () => ({
    drawer: true,
    rail: true,
    theme: null,
    items: [
      { title: 'Home', icon: 'mdi-home-city', link: '/private' },
      { title: 'Site settings', icon: 'mdi-application', link: '/private/site-settings' },
      { title: 'Media', icon: 'mdi-view-list', link: '/private/media' },
      { title: 'News', icon: 'mdi-newspaper-variant-multiple-outline', link: '/private/post' },
      { title: 'Feedback', icon: 'mdi-comment-quote-outline', link: '/private/feedback' },
    ]
  }),
  created: function() {
    this.$store.dispatch('member/reloadMemberList')
  },
  computed: {
    fullName: function() {
      return this.$store.getters['user/fullName']
    },
    selectedMediaIdList: function() {
      return this.$store.getters['mediaEditor/selectedMediaIdList']
    },
    selectedMediaCount: function() {
      let mediaIdList = this.selectedMediaIdList
      return mediaIdList.length
    },
    isEditorPanel: function() {
      return window.location.pathname === '/private/media'
    },
    totalMediaCount: function() {
      return this.$store.getters['mediaEditor/totalMediaCount']
    },
    withFeedbackCount: function() {
      return this.$store.getters['mediaEditor/withFeedbackCount']
    },
  },
  methods: {
    logout: function(){
      this.$store.dispatch('user/logout')
    },
    actionApproveSelectedMedia(){
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You will approve " + this.selectedMediaCount + " media to be published!",
        confirmButtonText: 'Approve!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('mediaEditor/approveSelectedMediaList')
          .then(() => {
            console.log('Get new media list')
            //this.$root.$refs.MediaGridView.getMediaList()
          })
        }
      });
    }
  }
}
</script>
